@import "./assets/scss/tuesday.min.css";
@import "@ng-select/ng-select/themes/default.theme.css";

@import "node_modules/@angular/material/prebuilt-themes/indigo-pink";
@import "node_modules/ngx-spinner/animations/ball-clip-rotate";
@import "node_modules/ngx-toastr/toastr";

@import 'node_modules/froala-editor/css/froala_editor.pkgd.min';
@import 'node_modules/froala-editor/css/froala_style.min';

@import "variables";
@import "global";
@import "common";
@import "yan";

