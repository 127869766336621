/* COMMON CLASSES & COMPONENTS */

/*** LOADING BAR SPINNER **/
ngx-loading-bar {
  z-index: 100002 !important;
}

/** MODAL **/
.cdk-overlay-container {
  z-index: 100100 !important;

  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 5px !important;
    overflow: hidden !important;
  }

  @media screen and (max-width: 800px) {
    .cdk-overlay-pane.yan-dialog {
      max-width: unset !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.cdk-global-overlay-wrapper{
  z-index: 100100 !important;
}

.full-screen-modal{
  min-width: calc(100vw);
  min-height: calc(100vh);
}

/** TEXT TRUNCATE */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*** MAT CHEKBOX **/
.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
  margin-right: 12px !important;
}

/** MAT  Multi select **/
.mat-pseudo-checkbox {
  border-width: 1px !important;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--red);
}

.mat-pseudo-checkbox-checked::after {
  top: 3px !important;
  left: 2px !important;
}


.mat-slider-horizontal {
  width: 100%;
  padding: 0 !important;
  height: 30px !important;

  .mat-slider-wrapper {
    top: 10px !important;
  }
}

.mat-slide-toggle-content {
  font-weight: 400 !important;
}

.mat-slide-toggle-bar{
  background: var(--bgd-gray);
}


/*** SLIDER **/
.mat-slider.mat-accent .mat-slider-track-fill, .mat-slider.mat-accent .mat-slider-thumb, .mat-slider.mat-accent .mat-slider-thumb-label{
  background: var(--primary);
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb, .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label{
  background: var(--orange);
}



/*** NG SELECT (AUTOCOMPLETE) ***/

.ng-select .ng-select-container {
  color: var(--black);
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: border-color 0.5s ease;
  height: 43px !important;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-radius: 10px !important;
}

.ng-dropdown-panel {
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 21px;
  border: 0;
  border-radius: 8px !important;
  overflow: hidden;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 10px;
  transition: all 200ms ease-in;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: var(--red);
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{
  border-radius: 12px !important;
}

/*** FROALA EDITOR **/
.fr-box.fr-basic {
  .fr-sticky-on {
    position: sticky !important;
  }

  .fr-element {
    min-height: 140px;
  }

  .fr-second-toolbar {
    #fr-logo {
      display: none;
    }
  }

  &.ng-invalid.ng-touched {
    & > div {
      border-color: var(--red);
    }
  }
}

.fr-view {
  p {
    margin-bottom: 0;
  }
}

.fr-sticky-dummy {
  display: none !important;
}

.fr-fullscreen-wrapper {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: #ffffff;
  overflow: hidden;

  .yan-page.yan-form {
    max-width: unset;
  }
}

.fr-popup.fr-active{
  z-index: 99999 !important;
}
