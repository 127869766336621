/** INPUT **/
.yan-input {
  color: var(--black);
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #fff;
  padding: 0 12px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  line-height: 45px;
  width: 100%;
  transition: border-color 0.5s ease;

  &.yan-color {
    cursor: pointer;
    height: 28px;
    width: 70px;
    border-radius: 6px;
  }

  &.search {
    padding-left: 45px;
    background: url("/assets/img/search.svg") 15px 50% no-repeat #fff;
    background-size: 20px 20px;
  }

  &:focus {
    border-color: var(--secondary);
    box-shadow: 0 0 3px var(--secondary);
  }

  &.is-invalid {
    border-color: var(--red) !important;

    &:focus {
      box-shadow: 0 0 3px var(--red) !important;
    }
  }

}

textarea.yan-input {
  border-radius: 6px;
  height: 80px;
  line-height: 25px;
  padding: 12px;
  font-weight: 400 !important;
}


/** BUTTON **/
.yan-btn {
  display: block;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 0 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  line-height: 30px;
  background: var(--primary);
  font-size: 14px;
  transition: background-color 500ms ease;
  border-radius: 30px;
  border: 1px solid transparent;
  position: relative;
  font-weight: 500;

  &:hover {
    background: var(--primary-dark);
  }

  &:active {
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  }

  &.secondary {
    background: var(--secondary);

    &:hover {
      background: var(--secondary-dark);
    }
  }

  &.reverse {
    transition: border-color 500ms ease, color 500ms ease, background 500ms ease;
    border-color: var(--primary);
    background: #fff;
    color: var(--primary);
  }

  &.reverse:hover {
    background: var(--primary);
    color: #fff;
  }

  &.white-btn,
  &.reset {
    transition: color 500ms ease;
    border-color: var(--brdr-gray);
    background: #fff;
    color: var(--gray-dark);
  }

  &.reset:hover {
    color: var(--black);
  }

  &:not(.right-ic) span {
    margin-left: 10px;
  }

  &.right-ic span {
    margin-right: 10px;
  }

  &[disabled] {
    background: var(--gray);
    cursor: inherit;
    box-shadow: unset;
  }
}

/** BTNS **/
.yan-btns {
  margin-top: 35px;
  overflow: hidden;
  display: flex;
  gap: 5px;
}

/** FORM FIELD **/
.yan-field {
  margin-bottom: 15px;
  position: relative;

  .yan-field-label {
    margin-bottom: 7px;

    label.yan-label {
      display: flex;
      align-items: center;
      font-weight: 500;

      span {
        display: block;

        &.mandatory {
          color: var(--primary);
          margin: 0 3px;
        }

      }
    }

    .yan-sub-label {
      display: block;
      font-size: 12px;
      color: var(--gray);
    }
  }

  .yan-field-content {
    display: block;
  }

  .yan-field-desc {
    margin-top: 5px;
    font-size: 12px;
    color: var(--gray);
  }

  .mat-form-field {
    display: block;
    background: #fff;

    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0;

      .mat-form-field-infix {
        border-top: unset;
        padding: 0;
        line-height: 44px;
      }

      .mat-form-field-suffix {
        top: 0.6em;
      }

      .mat-select-arrow-wrapper {
        transform: unset;
      }

      .mat-select-value,
      input.mat-input-element {
        color: var(--black);
        font-size: 14px;
      }

      .mat-form-field-outline-thick {
        & > div {
          border-width: 1px !important;
          color: var(--secondary);
        }
      }
    }
  }

  .mat-form-field-invalid .mat-form-field-outline-thick > div {
    color: var(--primary) !important;
  }
}

/** FLEX **/
.yan-flex {
  display: flex !important;
  gap: 10px;

  & > * {
    display: block;
    width: 100%;
  }
}

/*** YAN MENU **/
.mat-menu-panel {
  max-width: unset !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 21px;
  border-radius: 14px !important;
}

.yan-menu {
  max-width: 350px;
  min-width: 150px;

  .yan-menu-padding {
    padding: 12px 15px;
  }

  .yan-menu-ttl {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .mat-menu-item {
    line-height: 40px;
    height: 40px;
    border-radius: 5px;

    &:hover {
      color: var(--secondary);
    }
  }

  .sep {
    border-top: 1px solid var(--brdr-gray);
  }

  .yan-menu-content {
    margin-top: 20px;
    overflow: overly;
    padding-left: 10px;
    color: var(--gray);

    .yan-menu-item {
      margin-bottom: 12px;
    }
  }

  .yan-menu-actions {
    padding: 20px 20px 12px;
    border-top: 1px solid var(--brdr-gray);
    display: flex;
    align-items: center;


    .submit {
      margin-left: auto;
      line-height: 30px;
      background: var(--secondary);
    }

    .remove {
      font-weight: 500;
      color: var(--gray-dark);
      font-size: 13px;
      cursor: pointer;

      &:hover {
        color: var(--black);
      }
    }
  }
}

/*** YAN SECTION TITLE **/
.yan-section-title {
  padding-left: 15px;
  position: relative;
  font-weight: 500;
  color: var(--gray);
  font-size: 16px;

  &:after {
    position: absolute;
    content: '';
    display: block;
    height: 18px;
    width: 3px;
    background: var(--secondary);
    top: 50%;
    margin-top: -10px;
    left: 0;
  }
}

/*** AUTOCOMPLETE ***/
.yan-autocomplete {
  position: relative;


  .yan-autocomplete-panel {
    position: absolute;
    width: 100%;
    background: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 21px;
    border-radius: 10px;
    z-index: 1000;

    .no-item {
      height: 80px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 500;
      }
    }

    .autocomplete-loader {
      position: relative;
      min-height: 100px;
    }

    .result-items {
      position: relative;
      max-height: 300px;
      overflow: overlay;

      .ri-item {
        cursor: pointer;
        padding: 15px 20px;
        transition: background-color 200ms ease-in;

        &:hover {
          background-color: rgba(1, 142, 245, .1);
        }
      }
    }

    .new-item {
      color: var(--primary);
      cursor: pointer;
      border-top: 1px solid var(--brdr-gray);
      padding: 15px;
      text-align: center;
      border-radius: 0 0 10px 10px;

      &:hover {
        background: var(--bgd-gray);
      }
    }

  }
}

/*** YAN MODAL **/
.yan-modal-title {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 18px 20px;
}

.yan-modal-content {
  padding: 20px;
  overflow: auto;
  position: relative;
  max-height: calc(100vh - 250px);
  @media screen and (max-width: 800px) {
    max-height: unset !important;
    height: calc(100vh - 131px) !important;
  }
}

.yan-modal-btns {
  padding: 15px;
  display: flex;
  gap: 7px;
  background: #f1f5f9;

  & > * {
    line-height: 30px;
    min-width: 100px;
  }
}

/** YAN CHIP **/
.yan-chip-list {
  padding: 15px 10px;
  border-radius: 7px;
  border: 1px solid var(--brdr-gray);
  background: #fff;

  .mat-chip {
    background-color: var(--bgd-gray) !important;
    font-weight: 400;
    border-radius: 6px !important;
  }

  .mat-chip-remove {
    color: var(--red) !important;
    height: unset;
    font-weight: 500;
    font-size: 16px;
    margin-top: -6px;
  }

  input.mat-chip-input {
    border: none;
    font-weight: 400;
  }
}

/** FORM MODAL WRAPPER **/
.form-modal-wrapper {
  position: absolute;
  height: calc(100vh - 110px);
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 7px;
  z-index: 2000;
  width: 330px;
  transition: top 0.3s ease 0s;

  &.topper {
    left: 350px;
  }

  .wrapper-content {
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;

    .wc-header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      flex-shrink: 0;
      background: var(--bgd-gray);
      border-bottom: 1px solid var(--brdr-gray);

      .title{
        position: relative;
        padding-left: 15px;

        &:after {
          display: block;
          content: '';
          position: absolute;
          height: 14px;
          border-radius: 10px;
          width: 5px;
          background: var(--orange);
          left: 0;
          top: 4px;
        }
      }

      .close {
        margin-left: auto;
        width: 25px;
        height: 25px;
        border-radius: 30px;
        background: url("/assets/img/close.svg") center no-repeat #f5f5ff;
        background-size: 13px 13px;
        cursor: pointer;
      }
    }

    .wc-body {
      overflow: hidden;
      flex-grow: 1;
    }

  }
}

#app-container[dir="rtl"]{
  .form-modal-wrapper {
    left: unset;
    right: 0;

    &.topper {
      left: unset;
      right: 350px;
    }

    .wrapper-content {
      .wc-header {
        .title{
          padding-left: unset;
          padding-right: 15px;
          &:after {
            left: unset;
            right: 0;
          }
        }

        .close {
          margin-left: unset;
          margin-right: auto;
        }
      }
    }
  }
}

/*##### COLOR PICKER **/
.color-picker {
  border-radius: 5px;
  overflow: hidden;
  border-color: var(--brdr-gray) !important;
}
