:root {
  --primary: #553AD8;
  --primary-dark: #3721a5;
  --secondary: #018EF5;
  --secondary-dark: #063efc;

  --red: #ff3067;
  --green: #00c9b7;
  --orange: #ffcb2f;
  --purple: #3865fd;
  --blue: #018EF5;

  --gray: #647fa1;
  --gray-dark: #506580;

  --bgd-gray: #f1f5f9;
  --brdr-gray: rgba(0, 0, 0, .1);
  --black: #111;


  --font-thin: "Roboto-Thin";
  --font-Light: "Roboto-Light";
  --font-Regular: "Roboto-Regular";
  --font-Medium: "Roboto-Medium";
  --font-Bold: "Roboto-Bold";

}


